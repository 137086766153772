body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}

html,
body,
body #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
.pointer {
  cursor: pointer;
}
.transition {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* ****************** */
/* Override Bootstrap */
/* ****************** */
.btn:focus {
  box-shadow: none;
}
