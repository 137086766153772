/* 
 * For mobile screen
 *
**/

/****************
*	Main Page	*
*****************/
.main-wrapper {
  position: relative;
  height: 100%;
  padding-left: 0px;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.main-wrapper.open {
  padding-left: 280px;
}
.sidebar-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 280px;

  height: 100%;
  background: #405a6b;
}
.main-content-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #e7e7e7;
  overflow-y: auto;
  overflow-x: hidden;
}
/* 
 * For large screen
 *
**/
@media screen and (min-width: 1024px) {
  .main-content-wrapper {
    position: relative;
  }
}
/******************/
/* Sidebar Styles */
/******************/
/* 
* For mobile screen
*
**/
.sidebar {
  color: white;
  height: 100%;
  font-size: 12px;
}
.sidebar .header {
  padding: 16px;
  text-align: center;
}
.sidebar .content {
  width: 100%;
}
.sb-item-alone {
  display: block;
  cursor: pointer;
  color: inherit;
  width: 100%;
  line-height: 1;
  padding: 12px 25px;
  font-weight: bold;
}
.sb-item-alone:hover {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.1);
}
.sb-item-alone.active {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.2);
  pointer-events: none;
}
.sidebar .footer {
  padding: 10px;
  color: #ffffff;
  opacity: 1;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
}
.sidebar .footer .copyright-text {
  opacity: 0.4;
}

/***********/
/* Top-bar */
/***********/
.top-bar {
  background-color: #ffffff;
  height: 58px;
  line-height: 58px;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, 0.22);
}
.top-right-item > .text {
  min-width: 50px;
  max-width: 180px;
}
.top-view-changer {
  position: absolute;
  top: 15px;
  right: calc(50% - 89px);
}
.top-pro-pic {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  margin-top: 2px;
}
/* Dropdwon */
.dropdown-manu {
  position: fixed;
  right: 10px;
  top: 62px;
  width: 216px;
  border-radius: 4px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  z-index: 99;
  font-size: 15px;
  transition: all 500ms ease;
}
.dropdown-manu.hide {
  display: none;
}
.dropdown-manu .dd-item {
  display: block;
  padding: 10px 16px;
  border-bottom: 1px solid #f5f5f5;
  line-height: 24px;
}
.dropdown-manu .dd-item:hover {
  background-color: #f5f5f5;
}
.dropdown-manu .dd-item:last-child {
  border: none;
}
.dropdown-manu .dd-item .text {
  font-size: 12px;
  color: #aaaaaa;
}
