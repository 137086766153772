/* Basic Modal */
.lk-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.lk-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
}
.lk-modal {
  position: fixed;
  background-color: white;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
    0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
  text-align: left;
}

/* Confirm Modal */
.lk-modal-confirm {
  border-radius: 0.5rem;
  padding: 1rem;
}
.lk-modal-confirm .title {
  font-weight: bold;
  font-size: 1.2rem;
}
.lk-modal-confirm .description {
  font-size: 1rem;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
}
.lk-modal-confirm .buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 0.5rem;
}
.lk-modal button {
  cursor: pointer;
  font-weight: bold;
  border-radius: 0.3rem;
  padding: 0.4rem 1rem;
  outline: none;
  border: none;
}
.lk-modal button.confirm {
  background-color: #009cff;
  color: white;
}
.lk-modal button.confirm:hover {
  background-color: rgb(0, 156, 255, 0.8);
}
.lk-modal button.danger {
  background-color: #e8384f;
  color: white;
}
.lk-modal button.danger:hover {
  background-color: rgb(232, 56, 79, 0.8);
}
.lk-modal button.cancel {
  background-color: transparent;
  color: #009cff;
  border: #009cff 1px solid;
}
.lk-modal button.cancel:hover {
  background-color: rgb(0, 156, 255, 0.2);
}
